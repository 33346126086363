import React from "react";
import CacheBuster from "./CacheBuster.js";
import { Route, Switch, Router } from "react-router-dom";
import history from "./history.js";
import { Helmet } from "react-helmet";
import ogimgs from "./assets/img/banners/newban1.webp";
import Menu from "./header/Menu.jsx";
import "../src/assets/css/reactMaterial.css";
import "../src/assets/css/icon.css";
import "./assets/css/dk.css";
import BlogDetail from './components/AC-StaticPages/Blog/Blogdetail';

export default function App() {

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return (
          <>
            <Helmet
              titleTemplate="%s"

            >
              {/* <meta property="og:site_name" content="Backstage Pass" />
              <meta property="og:url" content="https://www.dev.backstagepass.co.in/" />
              <meta property="og:type" content="website" />
              <meta name="title" property="og:title" content="Best Game Design & Development college in India" />
              <meta name="image" property="og:image" content={ogimgs} />
              <meta property="og:image:type" content="image/png" />
              <meta property="og:image:width" content="1024" />
              <meta property="og:image:height" content="1024" />
              <meta name="description" property="og:description" content="Best Game Design & Development college in India - Backstage Pass" />
              <meta name="twitter:card" content="summary" />
              <meta name="twitter:site" description="Backstage Pass" />
              <meta name="twitter:title" content="Best Game Design & Development college in India" />
              <meta name="twitter:description" content="Best Game Design & Development college in India - Backstage Pass" />
              <meta name="twitter:image:src" content={ogimgs} />
              <meta name="twitter:domain" content="https://www.dev.backstagepass.co.in/"></meta>
              <link rel="image_src" href="{ogimgs}" />
              <meta name="twitter:image" property="og:image" content="{ogimgs}" />
              <meta
                name="description"
                content="Best Game Design & Development college in India - Backstage Pass"
              /> */}
            </Helmet>
            <Router history={history}>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={routeProps => {
                    return (
                      <>
                        <Menu {...routeProps} component="home" />
                      </>
                    );
                  }}
                />
                <Route
                  exact
                  path={"/courses/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="Courses" />
                  )}
                />
                <Route
                  exact
                  path={"/courses/bachelors-in-computer-science-and-game-development/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="BachelorsInComputerScienceGameDevelopment" />
                  )}
                />
                <Route
                  exact
                  path={"/courses/bachelors-in-game-art-and-design/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="GameArtDesign" />
                  )}
                />
                <Route
                  exact
                  path={"/courses/bachelors-in-augmented-reality-and-virtual-reality/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="AugmentedRealityAndVirtualReality" />
                  )}
                />
                <Route
                  exact
                  path={"/courses/masters-in-game-technology/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="MScGameTechnology" />
                  )}
                />
                <Route
                  exact
                  path={"/courses/advanced-diploma-in-game-development/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="AdvancedDiplomaInGameDevelopment" />
                  )}
                />
                <Route
                  exact
                  path={"/courses/advanced-diploma-in-3d-game-art-digital-sculpting/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="AdvancedDiplomain3DGameArtDigitalSculpting" />
                  )}
                />
                <Route
                  exact
                  path={"/courses/advanced-diploma-in-traditional-digital-art/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="AdvancedDiplomainTraditionalDigitalConceptArt" />
                  )}
                />
                <Route
                  exact
                  path={"/courses/diploma-in-game-design-production/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="DiplomainGameDesignProduction" />
                  )}
                />
                <Route
                  exact
                  path={"/courses/diploma-in-game-development-with-unity/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="DiplomaInGameDevelopmentWithUnity" />
                  )}
                />
                <Route
                  exact
                  path={"/courses/diploma-in-3d-environment-art-creation-for-games/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="DiplomaIn3dEnvironmentArtCreationForGames" />
                  )}
                />
                <Route
                  exact
                  path={"/about-us/our-story/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="AboutUs" />
                  )}
                />

<Route
                  exact
                  path={"/shortcourses/form/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="SkillDiploma" />
                  )}
                />

                <Route
                  exact
                  path={"/landingpage/diploma-and-advanced-diploma-courses/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="DiplomaAndAdvancedDiplomaCourses" />
                  )}
                />

                <Route
                  exact
                  path={"/landingpage/top-game-development-programs/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="TopGameDevelopmentPrograms" />
                  )}
                />

                <Route
                  exact
                  path={"/landingpage/masters-and-bachelors-programs/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="DiplomaAndAdvancedDiplomaCourses2" />
                  )}
                />
                <Route
                  exact
                  path={"/landingpage/diploma-and-advanced-diploma-game-development-courses/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="DiplomaAndAdvancedDiplomaGamingCourses" />
                  )}
                />
                <Route
                  exact
                  path={"/landingpage/masters-and-bachelors-game-development-courses/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="DiplomaAndAdvancedDiplomaGamingCourses2" />
                  )}
                />
                <Route
                  exact
                  path={"/landingpage/best-gaming-courses-in-india/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="AllDiplomaAndAdvancedDiplomaGamingCourses" />
                  )}
                />

<Route
                  exact
                  path={"/landingpage/game-art-programs/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="GameArtLP" />
                  )}
                />

<Route
                  exact
                  path={"/landingpage/short-course/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="ShortCourse" />
                  )}
                />
                {/* <Route
                  exact
                  path={"/landingpage/game-art-programs/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="StudentArtWork" />
                  )}
                /> */}
<Route
                  exact
                  path={"/landingpage/game-development-with-unity-programs/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="UnityGame" />
                  )}
                />
                <Route
                  exact
                  path={"/landingpage/certified-diploma-unity-game-development/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="CertifiedSkillUnity" />
                  )}
                />
                   <Route
                  exact
                  path={"/landingpage/certified-diploma-unreal-game-development/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="CertifiedSkillUnreal" />
                  )}
                />
                  <Route
                  exact
                  path={"/landingpage/certified-diploma-game-design-with-unreal-blueprints/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="UnrealBluePrint" />
                  )}
                />
            <Route
                  exact
                  path={"/landingpage/certified-diploma-3d-hard-surface-modeling-texturing/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="CertifiedSkillHardSurface" />
                  )}
                /> 
                <Route
                  exact
                  path={"/landingpage/game-development-with-unreal-programs/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="Unreal" />
                  )}
                />
                 <Route
                  exact
                  path={"/landingpage/game-development-programs/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="GamedevelopmentLP" />
                  )}
                />
                <Route
                  exact
                  path={"/about-us/affiliation-collaboration/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="Affilication" />
                  )}
                />
                <Route
                  exact
                  path={"/about-us/placement-support/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="Placements" />
                  )}
                />
                <Route
                  exact
                  path={"/admissions/admission-process/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="Admissions" />
                  )}
                />
                <Route
                  exact
                  path={"/admissions/financial-assistance-and-scholarship/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="FinancialAssistance" />
                  )}
                />
                <Route
                  exact
                  path={"/life-at-bsp/alumni-success/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="Alumni" />
                  )}
                />
                <Route
                  exact
                  path={"/life-at-bsp/student-showcase/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="StudentShowcase" />
                  )}
                />
                <Route
                  exact
                  path={"/life-at-bsp/accelerator-program/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="Accelerator" />
                  )}
                />
                 <Route
                  exact
                  path={"/terms-and-conditions/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="Terms" />
                  )}
                />
                 <Route
                  exact
                  path={"/privacy-policy/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="Privacy" />
                  )}
                />
                 <Route
                  exact
                  path={"/refund-policy/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="Refund" />
                  )}
                />
                <Route
                  exact
                  path={"/life-at-bsp/facilities/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="Facilities" />
                  )}
                />
                <Route
                  exact
                  path={"/coming-soon/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="ComingSoon" />
                  )}
                />
                {/* <Route
                  exact
                  path={"/about-us/our-mentors/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="Mentors" />
                  )}
                /> */}
                {/* <Route
                  exact
                  path={"/BlogDetails/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="BlogDetails" />
                  )}
                /> */}
                <Route
                  exact
                  path={"/life-at-bsp/events-workshops/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="Workshop" />
                  )}
                />
                {/* <Route
                  exact
                  path={"/scholarships/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="Scholarships" />
                  )}
                /> */}
                <Route
                  exact
                  path={"/blogs/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="Blog" />
                  )}
                />
                <Route
                  exact
                  path="/blogs/:id"
                  render={routeProps => (
                    <Menu {...routeProps} component="Blogdetail" />
                  )}
                />
                <Route
                  exact
                  path={"/contactus/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="ContactUs" />
                  )}
                />
                <Route
                  exact
                  path={"/Application-form/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="Applicationform" />
                  )}
                />
                 <Route
                  exact
                  path={"/sitemap/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="SiteMaps" />
                  )}
                /> 
              </Switch>
            </Router>
          </>
        );
      }}
    </CacheBuster>
  );
}
